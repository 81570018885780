<template>
  <div class="page-class page-user-list">
    <h1 class="page-title">{{ title }}</h1>
    <order-report-box
      :columns="columns"
      :rows="targetList"
      :baseUrl="baseUrl"
      :loading="loading"
      :showDownload="true"
      :showUpload="ifAdmin()"
      :showUploadSecondary="ifAdmin()"
      :showToolbar="true"
      :showPagination="true"
      :pagination="pagination"
      :searchItems="searchItems"
      downloadText="Download Targets"
      uploadText="Upload Target NOB"
      uploadSecondaryText="Upload Target Sales"
      emptyText="No Data found."
      @download="onExportCSV"
      @show-upload="onUpload"
      @show-upload-secondary="onUploadSecondary"
      @delete="onDelete"
    >
    </order-report-box>
  </div>
</template>

<script>
import moment from 'moment';
import Vue from 'vue';
import csv from 'csvtojson';
import router from '@/router';
import { mapState, mapActions } from 'vuex';
import _ from 'lodash';
import orderReportBox from '../../components/orderReportBox.vue';

export default {
  name: 'TargetList',
  components: {
    orderReportBox
  },
  metaInfo() {
    return {
      title: 'Target List',
      meta: [
        {
          name: 'description',
          content: `Manage Target`
        }
      ]
    };
  },
  mounted() {
    if (_.isEmpty(this.$route.query)) {
      this.listTarget({
        query: {
          ...this.$route.query,
          startdate: moment().format('YYYY-MM-DD'),
          enddate: moment()
            .add(1, 'month')
            .format('YYYY-MM-DD')
        }
      });
    } else {
      this.listTarget({ query: this.$route.query });
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.listTarget({ query: to.query });
    next();
  },
  data() {
    return {
      title: 'Target List',
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { 'text-center': true },
          textKey: 'rowNum',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Store Id',
          class: { 'text-center': true },
          textKey: 'storeid',
          width: '17%'
        },
        {
          type: 'string',
          headerText: 'Target NOB',
          class: { 'text-center': true },
          textKey: 'target',
          width: '17%'
        },
        {
          type: 'string',
          headerText: 'Target Sales',
          class: { 'text-center': true },
          textKey: 'targetSales',
          width: '17%'
        },
        {
          type: 'string',
          headerText: 'Date',
          class: { 'text-center': true },
          textKey: 'date',
          width: '13%'
        },
        {
          type: 'string',
          headerText: 'Created At',
          class: { 'text-center': true },
          textKey: 'createdAt',
          width: '10%'
        },
        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '16%',
          functions: {
            edit: false,
            delete: true
          }
        }
      ],
      searchItems: ['Store Id']
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('target', ['loading', 'baseUrl', 'targetList', 'pagination', 'downloadTargetList'])
  },
  watch: {
    downloadTargetList(newValue) {
      if (newValue.length) {
        let csv = 'Row Num,Store Id,Target NOB,Target Sales,Date,Created At\n';
        newValue.forEach(row => {
          Object.values(row).forEach((val, index) => {
            if ([1, 2, 3, 4, 5].includes(index)) {
              csv += `"${val}"`;
              csv += ',';
            }
          });
          csv += '\n';
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'Targets.csv';
        anchor.click();
      }
    }
  },
  methods: {
    ...mapActions('target', ['listTarget', 'deleteOne', 'upload', 'download']),
    ifAdmin() {
      const isAdmin = this.user.role === 99;
      if (isAdmin) {
        return true;
      }
      return false;
    },
    onExportCSV() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        this.download({ query: { ...this.$route.query } });
      } else {
        this.download({
          query: {
            ...this.$route.query,
            startdate: moment()
              .subtract(1, 'months')
              .format('MMM YYYY')
          }
        });
      }
    },
    onDelete({ row }) {
      if (this.user.role === 99) {
        Vue.swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this.",
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',

          preConfirm: () => {
            this.deleteOne({
              id: row.id
            });
          }
        });
      } else {
        Vue.swal({
          title: 'Access Denied',
          text: "You don't have permission to delete this.",
          type: 'error',
          confirmButtonText: 'Ok'
        });
      }
    },
    createCsv() {
      const storeids = [
        `D001\nD013\nD017\nD019\nD022\nD025\nD030\nD052\nD058\nD060\nD070\nD079\nD087\nD089\nD104\nD123\nD166\nD176\nD177\nD178\nD228\nD229\nD235\nD263\nD265\nD266\nD269\nD270\nD274\nD278\nD289\nD295\nD296\nD298\nD333\nD345\nD346\nD347\nD354\nD357\nD359\nD360\nD362\nD365\nD371\nD374\nD375\nD377\nD378\nD379\nD382\nD386\nD388\nD390\nD391\nD393\nD394\nD400\nD410\nH001\nH003\nH009\nH011\nH012\nH013\nH015\nH018\nH021\nH023\nH029\nH030\nH035\nH042\nH047\nH048\nH049\nH051\nH057\nH062\nH064\nH069\nH070\nH072\nH073\nH079\nH081\nH084\nH090\nH093\nH100\nH104\nH108\nH112\nH113\nH115\nH120\nH127\nH128\nH129\nH134\nH139\nM051\nS004\nS015\nS028\nS034\nS035\nS040\nS041\nS046\nS047\nS051\nS053\nS055\nS056\nS058\nS059\nS060\nS062\nS064\nS065\nS066\nS068\nS069\nS070\nS073\nS077\nS080\nS083\nS084\nS088\nS089\nS090\nS091\nS095\nS098\nV010\nX006\nX007\nX011\nX015\nX017\nX019\nX020\nX026\nX027\nX030\nX044\nX091`
      ];
      const date = moment().format('DD/MM/YYYY');
      const dateArray = [];
      const startDate = moment(date, 'DD/MM/YYYY').startOf('month');
      const endDate = moment(date, 'DD/MM/YYYY').endOf('month');
      while (startDate.isSameOrBefore(endDate)) {
        dateArray.push(startDate.format('DD/MM/YYYY'));
        startDate.add(1, 'days');
      }
      const csv = `Row Labels,${dateArray}\n${storeids}`;
      const anchor = document.createElement('a');
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
      anchor.target = '_blank';
      anchor.download = 'targetNOB.csv';
      anchor.click();
    },
    async onUpload() {
      await Vue.swal({
        title: 'Upload Target NOB',
        input: 'file',
        inputAttributes: {
          'aria-label': 'Upload Target NOB in CSV format'
        },
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Upload',
        denyButtonText: 'Download Sample',
        denyButtonColor: '#3a4',
        showDenyButton: true,
        showCancelButton: true,
        cancelButtonText: 'Close'
      }).then(result => {
        if (result.isDenied) {
          this.createCsv();
        }
        if (result.value) {
          if (result.value.name === 'targetNOB.csv') {
            const reader = new FileReader();
            reader.onload = () => {
              csv({
                noheader: false,
                output: 'json'
              })
                .fromString(reader.result)
                .then(csvRow => {
                  const inputs = csvRow.map(item => {
                    const abc = [];
                    Object.keys(item).forEach(key => {
                      if (item['Row Labels'].length === 4) {
                        if (moment(key, 'DD-MM-YYYY').isValid()) {
                          abc.push({
                            storeid: item['Row Labels'],
                            target: item[key],
                            date: moment(key, 'DD-MM-YYYY').format('YYYY-MM-DD')
                          });
                        }
                      }
                    });
                    return abc;
                  });
                  this.upload({ data: inputs.flat(1), type: 'NOB', router });
                });
            };
            reader.readAsBinaryString(result.value);
          }
          if (result.value.name !== 'targetNOB.csv') {
            Vue.swal({
              title: 'Invalid File',
              text: 'Please upload the correct file . File name should be targetNOB.csv',
              type: 'error',
              confirmButtonText: 'Ok'
            });
          }
        }
      });
    },
    createCsv2() {
      const storeids = [
        `D001\nD013\nD017\nD019\nD022\nD025\nD030\nD052\nD058\nD060\nD070\nD079\nD087\nD089\nD104\nD123\nD166\nD176\nD177\nD178\nD228\nD229\nD235\nD263\nD265\nD266\nD269\nD270\nD274\nD278\nD289\nD295\nD296\nD298\nD333\nD345\nD346\nD347\nD354\nD357\nD359\nD360\nD362\nD365\nD371\nD374\nD375\nD377\nD378\nD379\nD382\nD386\nD388\nD390\nD391\nD393\nD394\nD400\nD410\nH001\nH003\nH009\nH011\nH012\nH013\nH015\nH018\nH021\nH023\nH029\nH030\nH035\nH042\nH047\nH048\nH049\nH051\nH057\nH062\nH064\nH069\nH070\nH072\nH073\nH079\nH081\nH084\nH090\nH093\nH100\nH104\nH108\nH112\nH113\nH115\nH120\nH127\nH128\nH129\nH134\nH139\nM051\nS004\nS015\nS028\nS034\nS035\nS040\nS041\nS046\nS047\nS051\nS053\nS055\nS056\nS058\nS059\nS060\nS062\nS064\nS065\nS066\nS068\nS069\nS070\nS073\nS077\nS080\nS083\nS084\nS088\nS089\nS090\nS091\nS095\nS098\nV010\nX006\nX007\nX011\nX015\nX017\nX019\nX020\nX026\nX027\nX030\nX044\nX091`
      ];
      const date = moment().format('DD/MM/YYYY');
      const dateArray = [];
      const startDate = moment(date, 'DD/MM/YYYY').startOf('month');
      const endDate = moment(date, 'DD/MM/YYYY').endOf('month');
      while (startDate.isSameOrBefore(endDate)) {
        dateArray.push(startDate.format('DD/MM/YYYY'));
        startDate.add(1, 'days');
      }
      const csv = `Row Labels,${dateArray}\n${storeids}`;
      const anchor = document.createElement('a');
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
      anchor.target = '_blank';
      anchor.download = 'targetSales.csv';
      anchor.click();
    },
    async onUploadSecondary() {
      await Vue.swal({
        title: 'Upload Target Sales',
        input: 'file',
        inputAttributes: {
          'aria-label': 'Upload Target Sales in CSV format'
        },
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Upload',
        denyButtonText: 'Download Sample',
        denyButtonColor: '#3a4',
        showDenyButton: true,
        showCancelButton: true,
        cancelButtonText: 'Close'
      }).then(result => {
        if (result.isDenied) {
          this.createCsv2();
        }
        if (result.value) {
          if (result.value.name === 'targetSales.csv') {
            const reader = new FileReader();
            reader.onload = () => {
              csv({
                noheader: false,
                output: 'json'
              })
                .fromString(reader.result)
                .then(csvRow => {
                  const inputs = csvRow.map(item => {
                    const abc = [];
                    Object.keys(item).forEach(key => {
                      if (item['Row Labels'].length === 4) {
                        if (moment(key, 'DD-MM-YYYY').isValid()) {
                          abc.push({
                            storeid: item['Row Labels'],
                            target: item[key],
                            date: moment(key, 'DD-MM-YYYY').format('YYYY-MM-DD')
                          });
                        }
                      }
                    });
                    return abc;
                  });
                  this.upload({ router, data: inputs.flat(1), type: 'SALES' });
                });
            };
            reader.readAsBinaryString(result.value);
          }
          if (result.value.name !== 'targetSales.csv') {
            Vue.swal({
              title: 'Invalid File',
              text: 'Please upload the correct file . File name should be targetSales.csv',
              type: 'error',
              confirmButtonText: 'Ok'
            });
          }
        }
      });
    }
  }
};
</script>
